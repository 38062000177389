<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoBandeiras"
      :loading="loading"
      :paginate="true"
      sort-by="id_band"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Bandeiras</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="185"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" :label="item.descricao" />
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          icon
          v-can-access="186"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" v-can-access="186" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="1681"
          @click="deleteItem(item)"
        />

        <!-- <v-btn
          icon
          v-can-access="1681"
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
        -->
      </template>
    </BaseTable>
    <ModalCadBandeiras
      :dialog="dialog"
      :labelBtn="labelBtn"
      :tipoBandeiras="tipoBandeiras"
      @close="refresh()"
    />
  </div>
</template>

<script>
import sistema from "@/services/http/sistemaService";
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import BaseSegmento from "@/components/shared/BaseSegmento.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalCadBandeiras from "@/components/empresa/bandeira/ModalCadBandeiras";

export default {
  name: "TabelaBandeiras",

  components: {
    BaseTable,
    BaseSegmento,
    RegisterBottom,
    ModalCadBandeiras,
    IconBottom
  },

  data() {
    return {
      loading: false,
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      infoBandeiras: [],
      dialog: false,
      labelBtn: "Salvar",
      tipoBandeiras: {}
    };
  },

  methods: {
    async buscaBandeira() {
      try {
        const {
          data: { data }
        } = await sistema()
          .tipoBandeiras()
          .show({ per_page: -1 });
        this.infoBandeiras = data;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Error ao buscar"
        });
      }
    },
    editItem(item) {
      this.tipoBandeiras = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.buscaBandeira();
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          ` Deletar Bandeiras`,
          `Deseja deletar a Bandeira ${item.descricao} ?`
        );
        sistema()
          .tipoBandeiras(item.id_band)
          .delete(
            {},
            {
              notification: true,
              menssage: "Bandeira deletada com sucesso!"
            }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Bandeira"
        });
      } finally {
        this.buscaBandeira();
      }
    }
  },

  mounted() {
    this.buscaBandeira();
  }
};
</script>

<style></style>
